<template>
	<div>
		<el-dialog :draggable="true" width="60%" @close="Reset" top="5vh" :destroy-on-close="true"
			v-model="outerVisible" title="编辑">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<div class="title">购买人信息编辑</div>
					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="联系方式">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.phone" placeholder="请输入联系方式"></el-input>
					</el-form-item>
					<el-form-item label="详细地址">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<div class="title">发货信息编辑</div>
					<el-form-item label="快递公司名称">
						<span class="xx">*</span>
						<el-select clearable filterable v-model="ruleForm.delivery_code" placeholder="请选择快递公司名称(可搜索)"
							@change="change">
							<el-option v-for="item in list" :key="item.code" :label="item.name" :value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="快递单号">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.delivery_id" placeholder="请输入快递单号"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/order";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					order_sn: '',
					delivery_code: '',
					delivery_id: '',
					name: '',
					phone: '',
					address: ''
				},
				list: []
			}
		},
		methods: {
			// 获取信息
			getinfo(order_sn) {
				this.$http.post(api.info, {
						order_sn: order_sn
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
						}
					})
			},
			// 获取快递
			getList() {
				this.$http.post(api.Express)
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.list
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.phone === '') {
					ElMessage({
						message: '请输入联系方式',
						type: 'warning',
					})
				} else if (this.ruleForm.address === '') {
					ElMessage({
						message: '请输入详细地址',
						type: 'warning',
					})
				} else if (this.ruleForm.delivery_code === '') {
					ElMessage({
						message: '请选择快递公司名称',
						type: 'warning',
					})
				} else if (this.ruleForm.delivery_id === '') {
					ElMessage({
						message: '请输入快递单号',
						type: 'warning',
					})
				} else {
					this.$http.post(api.edit, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '编辑成功',
								})
								this.$emit('SubmitForm')
								this.outerVisible = false
							}
						})
				}
			},

			// 显示隐藏
			show(order_sn) {
				this.ruleForm.order_sn = order_sn
				this.getList()
				this.getinfo(order_sn)
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
	.title {
		font-size: 16px;
		padding-bottom: 20px;
	}
</style>