import request from './request'

const api = {
	// 获取列表
	lists: '/mall/Order/list',
	// 添加
	add: '/mall/Order/create',
	// 删除
	dele: '/mall/Order/delete',
	// 编辑
	edit: '/mall/Order/update',
	// 详情
	info: '/mall/Order/read',
	// 发货
	shipp: '/mall/Order/shipp',
	// 快递列表
	Express: '/mall/Express/getList',
	// 退款审核通过
	approved: '/mall/Order/approved',
	// 退款审核驳回
	reject: '/mall/Order/reject',
	// 订单物流查询
	logistics: '/mall/Order/logistics',
	// 村
	cunlist: '/village/lists',
}
export {
	request,
	api
}
