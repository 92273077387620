<template>
	<div>
		<el-dialog :draggable="true" width="75%" top="5vh" @close="close" v-model="outerVisible" title="查看物流">
			<div class="dialog logistics">
				<div class="title" v-if="list.length>0">
					物流公司:{{name||'--'}}
				</div>
				<div class="text" v-if="list.length>0">
					订单号:{{code||'--'}}
				</div>
				<div v-if="list.length===0"
					style="height: 700px; display: flex; width: 100%; align-items: center; justify-content: center; text-align: center;">
					<el-empty description="暂无物流信息" ></el-empty>
				</div>
				<div style="height: 700px;" v-if="list.length>0">
					<el-steps direction="vertical" :active="list.length">
						<el-step :title="item.status + ' ' + item.time" :description="item.context" v-for="item in list"
							:key="item.time"></el-step>
					</el-steps>

				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/order";
	export default {
		data() {
			return {
				outerVisible: false,
				list: [],
				name: '',
				code: ''
			}
		},
		methods: {
			// 获取信息
			getlogistics(order_sn) {
				this.$http.post(api.logistics, {
						order_sn: order_sn
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data
						}
					})
			},
			close() {
				this.name = ""
				this.code = ""
				this.list = []
			},
			// 显示隐藏
			show(order_sn, name, code) {
				this.name = name
				this.code = code
				this.getlogistics(order_sn)
				this.outerVisible = !this.outerVisible
			}
		}
	}
</script>

<style lang="scss" scoped>
	.logistics {
		padding: 0 80px;

		.title {
			font-size: 16px;
			color: #333;
		}

		.text {
			margin: 20px 0;
			color: #333;
		}
	}
</style>