<template>
	<div>
		<el-dialog :draggable="true" width="75%" top="5vh" v-model="outerVisible" title="查看详情">
			<div class="dialog no-bgs">
				<div class="title">商品清单</div>
				<div class="li">
					<!-- <div class="shop">
						<p>联丰村</p>
						<div>总计:260.00</div>
					</div> -->
					<div class="good" v-for="item in ruleForm.cart_info" :key="item.id">
						<img class="img" :src="item.product_cover" />
						<div class="info">
							<h3>{{item.product_name}}</h3>
							<div class="text">{{item.attr_name}}</div>
							<div class="price">
								<p>￥{{item.attr_price}}</p>
								<div>x{{item.num}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="title">购买人信息</div>
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.name"></el-input>
					</el-form-item>
					<el-form-item label="联系方式">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.phone"></el-input>
					</el-form-item>
					<el-form-item label="省">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.province"></el-input>
					</el-form-item>
					<el-form-item label="市">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.city"></el-input>
					</el-form-item>
					<el-form-item label="区">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.area"></el-input>
					</el-form-item>
					<el-form-item label="详细地址">
						<span class="xx"></span>
						<el-input disabled clearable v-model="address_info.address"></el-input>
					</el-form-item>
				</el-form>
				<div class="title" v-if="type==1">退款信息</div>
				<el-form-item label="申请类型" v-if="type==1">
					<span class="xx"></span>
					<el-input disabled clearable :value="typevalue"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="退款原因">
					<span class="xx"></span>
					<el-input disabled clearable v-model="orderRefund.refund_reason"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="其他原因">
					<span class="xx"></span>
					<el-input disabled clearable v-model="orderRefund.refund_other"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="联系方式">
					<span class="xx"></span>
					<el-input disabled clearable v-model="orderRefund.refund_phone"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="退款金额">
					<span class="xx"></span>
					<el-input disabled clearable v-model="orderRefund.refund_price"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="退款截图">
					<span class="xx"></span>
					<div class="dialog-up">
						<!-- 预览图 -->
						<div class="imgList" v-if="imgList.length>0" v-for="(item,index) in imgList" :key="index">
							<el-image class="dialog-img" :src="item" :preview-src-list="imgList" :initial-index="index"
								fit="fill">
							</el-image>
						</div>
					</div>
				</el-form-item>
				<el-form-item v-if="type==1&&ruleForm.status==8" label="退款去向">
					<span class="xx"></span>
					<el-input disabled clearable v-model="qxvalue"></el-input>
				</el-form-item>

				<el-form-item v-if="type==1" label="快递名称">
					<span class="xx"></span>
					<el-input disabled clearable v-model="ruleForm.delivery_name"></el-input>
				</el-form-item>
				<el-form-item v-if="type==1" label="快递单号">
					<span class="xx"></span>
					<el-input disabled clearable v-model="ruleForm.delivery_id"></el-input>
				</el-form-item>

			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/order";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {},
				address_info: {},
				type: 0,
				orderRefund: {},
				typevalue: '',
				imgList: [],
				qxvalue: '已原路退回'
			}
		},
		methods: {
			// 获取信息
			getinfo(order_sn) {
				this.$http.post(api.info, {
						order_sn: order_sn
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.orderRefund = res.data.orderRefund
							this.address_info = res.data.address_info
							this.imgList = this.orderRefund.refund_img ? this.orderRefund.refund_img.split(",") : []
							if (this.orderRefund.refund_type == 1) {
								this.typevalue = "仅退款"
							} else if (this.orderRefund.refund_type == 2) {
								this.typevalue = "退货退款"
							} else {
								this.typevalue = "我要退款(未到收到货)"
							}
						}
					})
			},
			// 显示隐藏
			show(order_sn, type) {
				this.type = type
				this.getinfo(order_sn)
				this.outerVisible = !this.outerVisible
			}
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		font-size: 16px;
		padding-bottom: 20px;
	}

	.li {
		padding: 0 40px;
		margin-bottom: 20px;

		.shop {
			margin-bottom: 10px;
			width: 100%;
			display: flex;
			align-items: center;

			p {
				flex: 1
			}
		}

		.good {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.info {
				padding-left: 10px;
				flex: 1;

				.text {
					margin: 5px 0;
				}

				.price {
					width: 100%;
					display: flex;
					align-items: center;

					p {
						flex: 1;
					}
				}
			}

			.img {
				width: 90px;
				height: 90px;
				border-radius: 5px;
			}
		}
	}
</style>
